<template>
  <div v-if="productDetail">
    <div
        class="
        mt-15
        h-15
        px-6
        bg-black
        flex
        justify-between
        text-white
        items-center
      "
    >
      <div class="text-xl">LOT# {{ productDetail.lot }} |
        <span class="">
            {{ salesStatus[productDetail.salesStatus]?.label }}
          </span></div>
      <div class="text-sm">등록일 : {{ productDetail.createdAt || '-' }}</div>
    </div>
    <div class="mt-14">
      <div>
        <div class="font-bold text-xl">셀러 정보</div>
        <div class="mt-2 text-sm">
          <div class="grid grid-cols-2 ProductInfoTable__row">
            <div class="grid grid-cols-2">
              <div class="flex">
                <div class="InfoTable__labelColumn">셀러명</div>
                <div>
                  {{ productDetail.partner.companyName || '-'  }}
                </div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">대표자</div>
                <div>
                  {{ productDetail.partner.ceoName || '-'  }}
                </div>
              </div>
            </div>
            <div class="grid grid-cols-2">
              <div class="flex">
                <div class="InfoTable__labelColumn">CS 담당자</div>
                <div>
                  <span> {{ productDetail.partner.managerName || '-' }} </span>
                </div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">CS 전화번호</div>
                <div>{{ productDetail.partner.csTelNo || '-' }}</div>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 ProductInfoTable__row">
            <div class="flex">
              <div class="InfoTable__labelColumn">주소</div>
              <div>
                {{ (productDetail.partner.address1 + productDetail.partner.address2)  || '-'}}
              </div>
            </div>
            <div class="flex">
              <div class="InfoTable__labelColumn">CS 이메일</div>
              <div>{{ productDetail.partner.csEmail || '-' }}</div>
            </div>
          </div>
        </div>
        <div class="flex justify-end mt-8">
          <button
              @click="gotoSeller(productDetail.sellerId)"
              class="
              h-10
              w-64
              inline-flex
              justify-center
              items-center
              bg-primary-light
              border border-primary
              text-sm
            "
          >
            셀러정보 보기
          </button>
        </div>
      </div>
      <div class="font-bold text-xl">기본 정보</div>
      <div class="mt-2 text-sm">
        <div class="grid grid-cols-2 ProductInfoTable__row">
          <div class="flex">
            <div class="InfoTable__labelColumn">
              작품명-한글
            </div>
            <div>{{ productDetail.titleKr || '-' }}</div>
          </div>
          <div class="flex">
            <div class="InfoTable__labelColumn">
              작품명-English
            </div>
            <div>{{ productDetail.titleEn || '-' }}</div>
          </div>
        </div>
        <div class="grid grid-cols-2 ProductInfoTable__row">
          <div class="flex">
            <div class="InfoTable__labelColumn">
              작가명-한글
            </div>
            <div>{{ productDetail.artistNameKr || '-' }}</div>
          </div>
          <div class="flex">
            <div class="InfoTable__labelColumn">
              작가명-English
            </div>
            <div>{{ productDetail.artistNameEn || '-' }}</div>
          </div>
        </div>
        <div class="grid grid-cols-2 ProductInfoTable__row">
          <div class="flex">
            <div class="InfoTable__labelColumn">카테고리</div>
            <div>
              {{ categoryDepth1Obj[productDetail.categoryDepth1Id] }} >
              {{ categoryDepth2Obj[productDetail.categoryDepth2Id] }}
            </div>
          </div>
          <div class="flex">
            <div class="InfoTable__labelColumn">태그(Tag)</div>
            <div>{{ productDetail.tags || '-' }}</div>
          </div>
        </div>
        <div class="grid grid-cols-2 ProductInfoTable__row">
          <div class="grid grid-cols-2">
            <div class="flex">
              <div class="InfoTable__labelColumn">
                제작시기
              </div>
              <div>
                {{ productDetail.period || '-' }}
              </div>
            </div>
            <div class="flex">
              <div class="InfoTable__labelColumn">제작년도</div>
              <div>{{ productDetail.makeYear || '-' }}</div>
            </div>
          </div>
          <div class="grid grid-cols-2">
            <div class="flex">
              <div class="InfoTable__labelColumn">국가</div>
              <div>{{ productDetail.country || '-' }}</div>
            </div>
            <div class="flex">
              <div class="InfoTable__labelColumn">에디션(n/n)</div>
              <div>{{ productDetail.edition || '-' }}</div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2 ProductInfoTable__row">
          <div class="grid grid-cols-2">
            <div class="flex">
              <div class="InfoTable__labelColumn">사이즈</div>
              <div>
                {{ productDetail.size || '-' }}
                <span v-if="productDetail.size"> cm</span>
              </div>
            </div>
            <div class="flex">
              <div class="InfoTable__labelColumn">보증서유무</div>
              <div>
                {{ productDetail.certificate ? '보증서 있음' : '보증서 없음' }}
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2">
            <div class="flex">
              <div class="InfoTable__labelColumn">상태</div>
              <div>{{ productDetail.status || '-' }}</div>
            </div>
            <div class="flex">
              <div class="InfoTable__labelColumn">이벤트코드</div>
              <div>{{ productDetail.eventCode || '-' }}</div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2 ProductInfoTable__row">
          <div class="flex">
            <div class="InfoTable__labelColumn">재질</div>
            <div>{{ productDetail.medium || '-' }}</div>
          </div>
          <div class="flex">
            <div class="InfoTable__labelColumn">서명정보</div>
            <div>{{ productDetail.signature || '-' }}</div>
          </div>
        </div>
        <div class="grid grid-cols-2 ProductInfoTable__row" style="min-height: 130px">
          <div class="flex">
            <div class="InfoTable__labelColumn">소장이력</div>
            <div>{{ productDetail.ownHistory || '-' }}</div>
          </div>
          <div class="flex">
            <div class="InfoTable__labelColumn">수록처</div>
            <div>{{ productDetail.recordedPlace || '-' }}</div>
          </div>
        </div>
        <div class="grid grid-cols-2 ProductInfoTable__row" style="min-height: 130px">
          <div class="flex">
            <div class="InfoTable__labelColumn">전시이력</div>
            <pre class="font-sans whitespace-pre-wrap">{{
                productDetail.exhibitionHistory || '-'
              }}</pre>
          </div>
          <div class="flex">
            <div class="InfoTable__labelColumn">작가정보</div>
            <pre class="font-sans whitespace-pre-wrap">{{
                productDetail.artistInfo || '-'
              }}</pre>
          </div>
        </div>
        <div class="grid grid-cols-2 ProductInfoTable__row" style="min-height: 130px">
          <div class="flex">
            <div class="InfoTable__labelColumn">상세설명</div>
            <pre class="font-sans whitespace-pre-wrap">{{
                productDetail.description || '-'
              }}</pre>
          </div>
        </div>
        <div class="ProductInfoTable__row">
          <div class="flex">
            <div class="InfoTable__labelColumn">상품 이미지</div>
            <div
                v-if="productDetail.productImageList"
                class="inline-flex flex-wrap"
            >
              <!--TODO: image list -->
              <a
                  v-for="(item, index) in productDetail.productImageList"
                  :key="index"
                  class="border w-25 h-25 mr-2"
                  :style="{
                  background: `url(${item.url}) no-repeat center`,
                  backgroundSize: 'cover',
                }"
                  :href="item.url"
                  target="_blank"
              ></a>
            </div>
            <div v-if="!productDetail.productImageList.length"  class="mr-4">
              등록된 이미지가 없습니다
            </div>
            <div
              v-if="productDetail.videoUrl"
              class="
                ProductImageItem
                w-25
                h-25
                bg-gray-200
                mr-4
                bg-cover bg-cnter
                relative
                flex
                items-center
                justify-center
              "
              :style="{ backgroundImage: `url(/images/video-thumb-img.png)` }"
            >
            </div>
          </div>
        </div>
        <div class="flex justify-end mt-8">
          <button
            @click="gotoRegisterStep(1)"
            class="
              h-10
              w-64
              inline-flex
              justify-center
              items-center
              bg-primary-light
              border border-primary
              text-sm
            "
            :class="productDetail.isDeleted ? 'opacity-30' : ''"
          >
            기본정보 수정하기
          </button>
        </div>
      </div>
    </div>
    <div class="mt-10">
      <div class="font-bold text-xl">판매 정보</div>
      <div class="mt-2 text-sm">
        <div class="grid grid-cols-2 ProductInfoTable__row">
          <div class="grid grid-cols-2">
            <div class="flex">
              <div class="InfoTable__labelColumn">판매옵션</div>
              <div>
                <span v-if="productDetail.enableBid">Bid Now</span>
                <span v-else-if="productDetail.buynowOnly">Buy now</span>
                <span v-else>-</span>
              </div>
            </div>
            <div class="flex">
              <div class="InfoTable__labelColumn">시작가</div>
              <div v-money="productDetail.startPrice"></div>
            </div>
          </div>
          <div class="flex">
            <div class="InfoTable__labelColumn">Buy Now 금액</div>
            <div v-money="productDetail.buynowPrice"></div>
          </div>
        </div>
        <div class="grid grid-cols-2 ProductInfoTable__row">
          <div class="grid">
            <div class="flex col">
              <div class="InfoTable__labelColumn">판매기간</div>
              <template v-if="productDetail.buynowOnly">
                <div v-if="productDetail.startDate">
                  {{ `${formatDate(productDetail.startDate)}` }}
                </div>
                <div v-else>-</div>
              </template>
              <template v-else>
                <div v-if="productDetail.startDate && productDetail.endDate">
                  {{
                    `${formatDate(productDetail.startDate)} ~ ${formatDate(productDetail.endDate)}`
                  }}
                </div>
                <div v-else>-</div>
              </template>
            </div>
          </div>
          <div class="grid-cols-2 grid">
            <div class="flex">
              <div class="InfoTable__labelColumn">구매제한</div>
              <div>
                {{ productDetail.buyLimit ? '있음' : '없음' }}
              </div>
            </div>
            <div class="flex">
              <div class="InfoTable__labelColumn">과세/면세</div>
              <div>
                {{ productDetail.taxFree ? '면세' : '과세' }}
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2 ProductInfoTable__row">
          <div class="flex">
            <div class="InfoTable__labelColumn">배송방법</div>
            <div>
              {{
                shippingTypeOptions.find(
                    (option) => option.value === productDetail.shippingTypeId
                )?.label || '-'
              }}
            </div>
          </div>
          <div class="grid grid-cols-2">
            <div class="flex">
              <div class="InfoTable__labelColumn">배송비</div>
              <div v-money="productDetail.shippingFee"></div>
            </div>
            <div class="flex">
              <div class="InfoTable__labelColumn">배송비 부담</div>
              <div>
                {{ productDetail.feePrepaidShipping ? '구매자 부담' : '판매자 부담' }}
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2 ProductInfoTable__row">
          <div class="flex">
            <div class="InfoTable__labelColumn">환불/취소</div>
            <div>
              <span> {{ productDetail.enableRefund == true ? '가능' : '불가능' }}</span>
            </div>
          </div>
          <div class="flex">
            <div class="InfoTable__labelColumn">Register Token</div>
            <div>
              <span> {{ productDetail.enableRegisterToken == true ? 'Private' : 'Public' }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end mt-8 items-center">
        <div v-if="!isSetSalesInfo" class="mr-3.5 text-xs">
          <div>아직 판매정보를 설정하지 않으셨습니다.</div>
          <div>판매정보를 설정하셔야 경매가 시작됩니다.</div>
        </div>
        <button
            @click="gotoRegisterStep(2)"
            class="
            h-10
            w-64
            inline-flex
            justify-center
            items-center
            bg-primary-light
            border border-primary
            text-sm
          "
            :class="productDetail.isDeleted ? 'opacity-30' : ''"
        >
          판매정보 수정하기
        </button>
      </div>
    </div>
    <div class="flex justify-center mt-14">
      <button
          class="
          w-60
          h-16
          border border-black
          bg-white
          text-black
          button-shadow
          flex
          items-center
          justify-center
          cursor-point
        "
          @click="handleClickDelete"
          :class="productDetail.isDeleted ? 'button-disabled' : ''"
      >
        <IconBase color="#262626">
          <RemoveIcon></RemoveIcon>
        </IconBase>
        <span class="ml-2">삭제하기</span>
      </button>
      <button
          class="
          w-60
          h-16
          border border-black
          bg-white
          text-black
          flex
          items-center
          justify-center
          ml-5
          button-shadow
          cursor-point
        "
          @click="handleToggleVisible"
          :class="productDetail.isDeleted ? 'button-disabled' : ''"
      >
        <template v-if="isInvisibled">
          <IconBase color="#262626">
            <VisibleIcon></VisibleIcon>
          </IconBase>
          <span class="ml-2">상품 공개</span>
        </template>
        <template v-else>
          <IconBase color="#262626">
            <InvisibleIcon></InvisibleIcon>
          </IconBase>
          <span class="ml-2">상품 숨기기</span>
        </template>
      </button>
      <button
          class="
          w-60
          h-16
          border border-black
          button-shadow
          cursor-point
          flex
          items-center
          justify-center
          ml-5
        "
          @click="handleClickComplete"
          :class="productDetail.isDeleted ? 'button-disabled' : ''"
      >
        <IconBase>
          <CheckCircleIcon></CheckCircleIcon>
        </IconBase>
        <span class="ml-2" @click="handleClickRegister">
          {{ isConfirmSalesWarn ? '판매등록 완료하기' : '상품등록하기' }}
        </span>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import IconBase from '@/components/icons/IconBase.vue';
import router from '@/router/index';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format';
import useCategoryOptions from '@/mixins/useCategoryOptions';
import useShippingTypeOptions from '@/mixins/useShippingTypeOptions';
import getServerErrorMessage from '@/utils/getServerErrorMessage';
import salesAndOrderStatus from '@/constants/salesAndOrderStatus';
import RemoveIcon from '@/components/icons/RemoveIcon.vue';
import VisibleIcon from '@/components/icons/VisibleIcon.vue'
import InvisibleIcon from '@/components/icons/InvisibleIcon.vue'

export default defineComponent({
  name: 'Step3',
  components: {
    IconBase,
    CheckCircleIcon,
    RemoveIcon,
    VisibleIcon,
    InvisibleIcon
  },
  mixins: [scrollToTopOnMountMixin],
  props: {
    productIdQuery: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { salesStatus } = salesAndOrderStatus();
    const { categoryDepth1Obj, categoryDepth2Obj } = useCategoryOptions();
    const { shippingTypeOptions } = useShippingTypeOptions(false);
    const productDetail = ref<any>(null);
    const gotoSeller = (memberId) => {
      router.push({
        path: `/app/member/detail/${memberId}`
      })
    }
    const isInvisibled = computed(() =>
        productDetail.value.isInvisibled
    );
    // 판매중
    const isInSale = computed(() =>
        productDetail.value.salesStatus !== 'invisible' &&
        productDetail.value.salesStatus !== 'display'
    );
    // 판매정보 설정했는지
    const isSetSalesInfo = computed(() => {
      const { enableBid, buynowOnly } = productDetail.value;
      return enableBid || buynowOnly;
    });
    const isConfirmSalesWarn = ref(false);

    onMounted(async () => {
      console.log('props.productIdQuery', props.productIdQuery);

      // step3에는 임시저장한 상품아이디가 필요하다.
      if (!props.productIdQuery) {
        alert('There is no product id');
        router.push({
          path: '/app/product/register',
          query: {
            step: 1,
          },
        });
      } else {
        // 저장된 상품정보를 가져온다.
        await fetchProduct()
      }
    });

    const fetchProduct = async () => {
      try {
        const { data } = await partnerAPI.adminProduct.adminProductSingle({
          productId: parseInt(props.productIdQuery),
        });

        console.log('data', data);
        productDetail.value = (data as any).data;
        console.log( productDetail.value.biddingCount > 0, productDetail.value)
      } catch (error) {
        console.error(error);
      }
    }

    const gotoRegisterStep = (step: number) => {
      if(productDetail.value.isDeleted) {
        alert('이미 삭제된 상품입니다.')
        return
      }
      if (productDetail.value) {
        router.push({
          path: '/app/product/register',
          query: {
            step,
            productId: props.productIdQuery,
          },
        });
      }
    };

    const handleClickRegister = async () => {
      if(productDetail.value.isDeleted) {
        alert('이미 삭제된 상품입니다.')
        return
      }
      if (isInSale.value) {
        alert('이미 판매등록이 완료되었습니다.');
        return;
      }
      if (!isSetSalesInfo.value) {
        alert('판매정보를 먼저 설정하셔야 합니다.');
        return;
      }
      if (!isConfirmSalesWarn.value) {
        if (confirm('상품이 등록되면 수정할 수 없습니다.')) {
          isConfirmSalesWarn.value = true;
        }
        return;
      }
      try {
        // 상품등록 (상태변경) 이름 변경 예정
        const { data } = await partnerAPI.adminProduct.adminProductStatusUpdate({
          productId: parseInt(props.productIdQuery),
          salesStatus: 'sale',
        });

        console.log('data', data);
        alert('판매등록 완료됐습니다.');

        router.push('/app/product');
      } catch (e) {
        console.error(e);
        alert(getServerErrorMessage(e));
      }
    };

    const handleClickDelete = async () => {
      if(productDetail.value.isDeleted) {
        alert('이미 삭제된 상품입니다.')
        return
      }
      if (confirm('상품 삭제시 복원이 불가능합니다.\n' +
          '정말로 삭제하시겠습니까?')) {
        try {
          const { data } = await partnerAPI.adminProduct.adminProductDelete({
            productId: parseInt(props.productIdQuery),
          });
          alert((data as any).message);
          router.push('/app/product');
        } catch (e) {
          alert(getServerErrorMessage(e));
        }
      }
    };

    const handleToggleVisible = async () => {
      if(productDetail.value.isDeleted) {
        alert('이미 삭제된 상품입니다.')
        return
      }
      // 상품보일경우
      if(!isInvisibled.value) {
        try {
          //숨기기
          await partnerAPI.adminProduct.adminProductHide({
            productId: parseInt(props.productIdQuery)
          })
          alert('상품이 숨김처리 되었습니다.');
          await fetchProduct();
        } catch (e) {
          alert(getServerErrorMessage(e));
        }
      } else {
        try {
          //보이기
          await partnerAPI.adminProduct.adminProductShow({
            productId: parseInt(props.productIdQuery)
          })
          alert('상품이 공개처리 되었습니다.');
          await fetchProduct();
        } catch (e) {
          alert(getServerErrorMessage(e));
        }
      }
    }

    const formatDate = (date: string) => {
      console.log('date', date);

      return format(new Date(date), 'yyyy/MM/dd HH:mm');
    };

    return {
      isInvisibled,
      productDetail,
      gotoRegisterStep,
      handleClickRegister,
      handleClickDelete,
      formatDate,
      gotoSeller,
      salesStatus,
      isInSale,
      isConfirmSalesWarn,
      isSetSalesInfo,
      categoryDepth1Obj,
      categoryDepth2Obj,
      shippingTypeOptions,
      handleToggleVisible,
    };
  },
});
</script>

<style lang="scss" scoped>
.ProductInfoTable__row {
  border-bottom: 1px solid #ddd;
  padding: 0.65rem 0;
  &:last-child {
    border-bottom: none;
  }
}

.InfoTable__labelColumn {
  color: #999;
  width: 144px;
  flex-shrink: 0;
}
.button-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}
.button-disabled {
  background: #DDDDDD;
  border: 1px solid #999999;
  color: #999999;
}
</style>
