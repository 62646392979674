<template>
  <div class="inline-flex h-8 items-center">
    <span v-if="isRequired" class="text-red-500 text-sm mr-1 self-start pt-1"
      >*</span
    >
    <span>
      {{ name }}
    </span>

    <span v-if="popoverDescription" class="ml-2 relative">
      <Popover :description="popoverDescription">
        <QuestionIcon></QuestionIcon>
      </Popover>
    </span>

    <span v-if="description" class="ml-4 text-xs text-gray-600">
      {{ description }}
    </span>
  </div>
</template>
<script>
import QuestionIcon from '@/components/icons/QuestionIcon.vue';
import Popover from '@/components/Popover.vue';

export default {
  components: {
    QuestionIcon,
    Popover,
  },
  props: {
    isRequired: Boolean,
    name: String,
    description: String,
    popoverDescription: String,
  },
};
</script>

<style lang="scss" scoped></style>
