import partnerAPI from '@/service/partnerAPI';
import { onMounted, ref } from 'vue';
export default function useShippingTypeOptions(onlyCompany) {
  const shippingTypeOptions = ref([]);

  onMounted(() => {
    partnerAPI.adminProduct.adminShippingTypeList().then(({ data }) => {
      shippingTypeOptions.value = (data as any).data.map((v: any) => ({
        label: v.shippingTypeName,
        value: v.shippingTypeId,
      }));
      if (onlyCompany) {
        shippingTypeOptions.value.splice(0,1);
        console.log(shippingTypeOptions.value);
      }
    });
  });

  return {
    shippingTypeOptions,
  };
}
