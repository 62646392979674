
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import _ from 'lodash';
import FormLabel from '@/components/FormLabel.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import DropboxIcon from '@/components/icons/DropboxIcon.vue';
import Input from '@/components/Input.vue';
import Select from '@/components/Select.vue';
import Popover from '@/components/Popover.vue';
import ImportantIcon from '@/components/icons/ImportantIcon.vue';
import CategoryTitle from './CategoryTitle.vue';
import IconBase from '@/components/icons/IconBase.vue';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import partnerAPI from '@/service/partnerAPI';
import Autocomplete from '@/components/Autocomplete.vue';
import router from '@/router';
import axios from 'axios';
import useStatusOptions from '@/mixins/useStatusOptions';
import useCategoryOptions from '@/mixins/useCategoryOptions';
import getServerErrorMessage from '@/utils/getServerErrorMessage';
import convertNumWithComma, { removeComma } from '@/utils/convertNumWithComma';

export default defineComponent({
  name: 'Step1',
  components: {
    CategoryTitle,
    FormLabel,
    Input,
    Select,
    ImportantIcon,
    Popover,
    CheckCircleIcon,
    IconBase,
    DropboxIcon,
    Autocomplete,
  },
  mixins: [scrollToTopOnMountMixin],
  props: {
    productIdQuery: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const artistOptions = ref([]);
    const countryOptions = ref([]);
    const mediumOptions = ref([]);
    const { statusOptions } = useStatusOptions();
    const { categoryOptions } = useCategoryOptions();
    const periodOptions = ref([]);
    const imageFilesInputEl = ref<HTMLInputElement | null>(null);

    const isCreateProduct = computed(() => !props.productIdQuery); // 새로운 상품정보 등록인지. 상품 아이디가 존재 유무로 확인한다

    const savedProductData = ref<any>({} as any); // 서버에서 가져온 상품정보
    const productData = ref<any>({
      productImageList: [],
      signatureExist: true,
      status: '',
    } as any); // 폼으로 제어할 상품정보

    const selectedCategoryDepth1 = computed(() =>
        categoryOptions.value.find(
            (v) => v.categoryId === productData.value.categoryDepth1Id
        )
    );

    const handleChangeProductData = (field: string, value: any) => {
      // productData.value[field] = value;
      productData.value = {
        ...productData.value,
        [field]: value,
      };
      console.group('handleChangeProductData');
      console.log('field, value', field, value);
      console.groupEnd();
    };

    const handleRemoveProductImage = (index, url) => {
      const nextList = [...(productData.value.productImageList || [])];
      nextList.splice(index, 1);
      productData.value.productImageList = nextList;
    };

    const initProductData = async () => {
      if (!isCreateProduct.value) {
        try {
          const res = await partnerAPI.adminProduct.adminProductSingle({
            productId: Number(props.productIdQuery),
          });

          const data = (res.data as any).data;
          savedProductData.value = data;
          productData.value = data;
        } catch (error) {
          console.error(error);
        }
      }
    };

    onMounted(initProductData);

    onMounted(() => {
      // get artist options
      partnerAPI.adminProduct
          .adminOptionList({
            type: 'artist',
          })
          .then(({ data }) => {
            artistOptions.value = (data as any).data.map((v: any) => ({
              label: `${v.kor} ${v.eng}`,
              value: `${v.kor} ${v.eng}`,
            }));
          });

      // get country options
      partnerAPI.adminProduct
          .adminOptionList({
            type: 'country',
          })
          .then(({ data }) => {
            countryOptions.value = (data as any).data.map((v: any) => ({
              label: `${v.kor} ${v.eng}`,
              value: `${v.kor} ${v.eng}`,
            }));
          });

      // get medium options
      partnerAPI.adminProduct
          .adminOptionList({
            type: 'medium',
          })
          .then(({ data }) => {
            mediumOptions.value = (data as any).data.map((v: any) => ({
              label: `${v.kor} ${v.eng}`,
              value: `${v.kor} ${v.eng}`,
            }));
          });

      // get period options
      partnerAPI.adminProduct
          .adminOptionList({
            type: 'period',
          })
          .then(({ data }) => {
            periodOptions.value = (data as any).data.map((v: any) => ({
              label: v.kor,
              value: v.kor,
            }));
          });
    });

    watch([categoryOptions, savedProductData], ([categories, product]) => {
      if (categories.length > 0) {
        console.log(categories);

        console.log('product.categoryId', product.categoryId);

        // 저장된 상품 수정일 때
        if (product.categoryDepth1Id) {
          handleChangeProductData('categoryDepth1Id', product.categoryDepth1Id);

          // depth2 가 없는 카테고리도 있으므로 확인 후 업데이트
          if (product.categoryDepth2Id) {
            handleChangeProductData(
                'categoryDepth2Id',
                product.categoryDepth2Id
            );
          }
        } else {
          // 최초 등록일 때
          handleClickCategory(0);
        }
      }
    });

    const handleClickCategory = (index: number) => {
      const hasChildCategories =
          categoryOptions.value[index].children.length > 0;

      handleChangeProductData(
          'categoryDepth1Id',
          categoryOptions.value[index].categoryId
      );

      if (hasChildCategories) {
        // children 중에 첫번째 항목을 기본값으로 선택
        handleChangeProductData(
            'categoryDepth2Id',
            categoryOptions.value[index].children[0].categoryId
        );
      } else {
        handleChangeProductData('categoryDepth2Id', null);
      }
    };

    const handleClickSubCategory = (subCategory: any) => {
      handleChangeProductData('categoryDepth2Id', subCategory.categoryId);
    };

    const handleDragoverImageInput = async (e) => {
      e.preventDefault();
      if (!e.currentTarget.classList.contains('is--drag')) {
        e.currentTarget.classList.add('is--drag');
      }
    };

    const handleDragleaveImageInput = async (e) => {
      e.currentTarget.classList.remove('is--drag');
    };

    const handleClickImageInput = async (e) => {
      if (imageFilesInputEl.value) {
        imageFilesInputEl.value.click();
      }
    };

    const handleChangeImageInput = async (e) => {
      if (e.target.files) {
        console.log('e.target.files', e.target.files);
        uploadImageFiles(e.target.files);
      }
    };

    const handleDropImageInput = async (event) => {
      event.preventDefault();
      event.currentTarget.classList.remove('is--drag');

      //TODO: 사파리에서 파일이 2번 올라간다. input change가 같이 작동함. 크롬은 아님.
      if (imageFilesInputEl.value && imageFilesInputEl.value.files !== null) {
        imageFilesInputEl.value.files = event.dataTransfer.files;
        uploadImageFiles(event.dataTransfer.files);
      }
    };

    const uploadImageFiles = async (files: FileList) => {
      const MAX_IMAGES = 10;
      const uploadableLength =
          MAX_IMAGES - productData.value.productImageList.length;

      if (files.length > uploadableLength) {
        alert(`이미지 파일은 ${MAX_IMAGES}개까지 등록 가능합니다.`);
      }

      try {
        let uploadedImageUrls: { [key: string]: any }[] = [];

        for (let i = 0; i < Math.min(files.length, uploadableLength); i++) {
          const formData = new FormData();
          formData.append('file', files[i]);

          const { data: uploadedImageUrl } = await axios.post(
              process.env.VUE_APP_API_HOST + '/admin-api/upload',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
          );

          uploadedImageUrls = [
            ...uploadedImageUrls,
            {
              productId: !props.productIdQuery // 상품아이디, create일때는 null
                  ? null
                  : productData.value.productId,
              url: uploadedImageUrl,
            },
          ];
        }

        const nextImageUrls =
            productData.value.productImageList.concat(uploadedImageUrls);

        handleChangeProductData('productImageList', nextImageUrls);
      } catch (error) {
        console.error(error);
      }
    };

    const handleSubmitStep1 = _.throttle(async () => {
      // TODO: form validation
      const submitData = { param: { ...productData.value } };
      console.log('submitData', submitData);
      try {
        // NOTE: 상품 업데이트
        await partnerAPI.adminProduct.adminProductUpdate(submitData as any);
        alert('상품 정보가 수정되었습니다');
        // step3로 이동.
        router.push({
          path: '/app/product/register',
          query: {
            step: 3,
            productId: productData.value.productId,
          },
        });
      } catch (e) {
        console.error(e);
        alert(getServerErrorMessage(e));
      }
    }, 2000);

    return {
      productData,
      handleChangeProductData,
      categoryOptions,
      artistOptions,
      countryOptions,
      mediumOptions,
      statusOptions,
      periodOptions,
      selectedCategoryDepth1,
      handleClickCategory,
      handleClickSubCategory,
      handleSubmitStep1,
      handleDragoverImageInput,
      handleDragleaveImageInput,
      handleDropImageInput,
      handleClickImageInput,
      handleChangeImageInput,
      imageFilesInputEl,
      handleRemoveProductImage,
      convertNumWithComma,
      removeComma,
    };
  },
});
