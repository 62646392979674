<template>
  <CategoryTitle
      title="카테고리 선택"
      description="상품의 카테고리를 선택해주세요. 카테고리에 따라 필수 입력 항목이 달라질 수 있습니다. "
      className="mt-10"
  ></CategoryTitle>

  <div class="mt-8">
    <div class="grid grid-cols-6 gap-2">
      <template v-for="(category, index) in categoryOptions"
                :key="index">
        <button
            v-if="category.categoryId !== 6"
            @click="handleClickCategory(index)"
            :class="[
            'h-10 border hover:border-primary hover:bg-primary-light',
            category.categoryId === productData.categoryDepth1Id && 'border-primary bg-primary-light',
          ]"
        >
          {{ category.categoryName }}
        </button>
      </template>
    </div>
  </div>

  <!-- sub categoryOptions -->
  <div class="flex mt-6" v-if="selectedCategoryDepth1">
    <div
        class="flex-shrink-0 text-xl font-bold w-48 px-4"
        style="word-break: keep-all"
    >
      {{ selectedCategoryDepth1.description }}
    </div>
    <div class="inline-flex flex-wrap items-start justify-start">
      <Popover
          v-for="(subCategory, index) in selectedCategoryDepth1.children"
          :key="index"
          :description="subCategory.description"
          className="mr-2 mb-2"
      >
        <button
            :class="[
            'inline-flex justify-center items-center h-10 px-3 border hover:border-primary hover:bg-primary-light',
            subCategory.categoryId === productData.categoryDepth2Id &&
              'border-primary bg-primary-light',
          ]"
            @click="handleClickSubCategory(subCategory)"
        >
          {{ subCategory.categoryName }}
        </button>
      </Popover>
    </div>
  </div>

  <CategoryTitle
      title="상품이미지 업로드"
      className="mt-10"
  ></CategoryTitle>

  <div class="mt-6">
    <div class="flex">
      <!-- TODO: 드래그오버 스타일 추가-->
      <div
          class="
          border
          flex
          items-center
          justify-center
          cursor-pointer
          drag-container
        "
          style="width: 780px; height: 200px"
          @dragover.prevent="handleDragoverImageInput"
          @dragleave="handleDragleaveImageInput"
          @drop="handleDropImageInput"
          @click="handleClickImageInput"
      >
        <input
            type="file"
            multiple
            name="productImageList"
            accept=".pdf,.jpg,.jpeg,.png"
            class="w-px h-px opacity-0 overflow-hidden absolute"
            ref="imageFilesInputEl"
            @change="handleChangeImageInput"
        />

        <div class="flex flex-col items-center drag-text">
          <IconBase
              :width="48"
              :height="48"
              color="#49CDDA"
              viewBox="0 0 35 35"
          >
            <DropboxIcon></DropboxIcon>
          </IconBase>
          <div class="mt-5 text-blue-sky">클릭하거나 이미지를 드래그해서 올려주세요.</div>
          <div class="text-xs mt-2">10개까지 한번에 업로드할 수 있습니다.</div>
        </div>
      </div>

      <div class="text-sm ml-10">
        <div class="flex items-center">
          <ImportantIcon></ImportantIcon>
          <span class="ml-2">상품이미지 등록 가이드</span>
        </div>
        <div class="mt-5">
          <div>
            - 가로, 세로 최소 800px 이상의 jpg, png, gif 파일을 등록해주세요.
          </div>
          <div>- 용량이 10MB 이하인 이미지만 등록할 수 있습니다.</div>
          <div>
            - 상품을 자세히 확인할 수 있도록 다양하게 촬영한 여러 장의 사진을
            등록해주세요.
          </div>
          <div>- 미술품의 경우 아래 사진은 필수적으로 등록해주세요.</div>
          <div>
            - 전체 사진/프레임 사진/서명 사진/조각품의 경우 전후좌우 사진
          </div>
          <div>- 재질을 알 수 있는 근접 촬영 사진</div>
        </div>
      </div>
    </div>
    <div class="mt-6">
      <div style="width: 720px">
        <FormLabel
            name="상품 동영상 URL"
            description="Youtube의 공유 URL을 입력해주세요. (Youtube에 업로드된 영상만 가능합니다.)"
        ></FormLabel>
        <Input
            className="w-full"
            :value="productData.videoUrl"
            placeholder="https://youtu.be/bGii1-k-rSo"
            :onChange="(e) => handleChangeProductData('videoUrl', e.target.value)"
        ></Input>
      </div>
    </div>

    <div class="mt-4 flex flex-wrap">
      <div
        v-for="(item, index) in productData.productImageList"
        :key="item.url"
        class="
        ProductImageItem
        w-25
        h-25
        bg-gray-200
        mr-4
        bg-cover bg-cnter
        relative
        flex
        items-center
        justify-center
        "
        :style="{ backgroundImage: `url(${item.url})` }"
      >
        <button
          class="p-2 bg-red-500 bg-opacity-60 text-white rounded text-sm"
          @click="handleRemoveProductImage(index, item.url)"
        >
          삭제
        </button>
      </div>
      <div
          v-if="productData.videoUrl"
          class="
          ProductImageItem
          w-25
          h-25
          bg-gray-200
          mr-4
          bg-cover bg-cnter
          relative
          flex
          items-center
          justify-center
        "
          :style="{ backgroundImage: `url(/images/video-thumb-img.png)` }"
      >
        <button
            class="p-2 bg-red-500 bg-opacity-60 text-white rounded text-sm"
            @click="() =>  productData.videoUrl = null"
        >
          삭제
        </button>
      </div>
    </div>
  </div>

  <CategoryTitle
      title="상품정보 입력"
      description="상품의 상세정보를 입력해주세요. (* 은 필수입력 항목입니다.)"
      className="mt-16"
  ></CategoryTitle>
  <p class="mt-8"><span class="text-red-600">*</span> 작품명과 작가명은 한글/영어 중 1개는 필수로 입력해야 합니다.</p>
  <div class="mt-6">
    <div class="grid grid-cols-2 gap-10">
      <div>
        <FormLabel
          name="작품명(제품명이나 모델명) - 한글"
          description="한글 작품명(제품명)을 입력해주세요."
        ></FormLabel>
        <Input
          className="w-full"
          :value="productData.titleKr"
          placeholder="예 : 기억의 지속"
          :onChange="(e) => handleChangeProductData('titleKr', e.target.value)"
        ></Input>
      </div>
      <div>
        <FormLabel
          name="작품명(제품명이나 모델명) - English"
          description="영어 작품명(제품명)을 입력해주세요."
        ></FormLabel>
        <Input
          className="w-full"
          :value="productData.titleEn"
          placeholder="예 : La persistencia de la memoria"
          :onChange="(e) => handleChangeProductData('titleEn', e.target.value)"
        ></Input>
      </div>
    </div>
    <div class="mt-6 grid grid-cols-2 gap-10">
      <div>
        <FormLabel
          name="작가명(or 브랜드/디자이너명) 한글"
          description="한글 작가명(or 브랜드/디자이너명)을 입력해주세요."
        ></FormLabel>
        <Input
          className="w-full"
          :value="productData.artistNameKr"
          placeholder="예 : 살바도르 달리"
          :onChange="(e) => handleChangeProductData('artistNameKr', e.target.value)"
        ></Input>
      </div>
      <div>
        <FormLabel
          name="작가명(or 브랜드/디자이너명) - English"
          description="영어 작가명(or 브랜드/디자이너명)을 입력해주세요."
        ></FormLabel>

        <Input
          className="w-full"
          :value="productData.artistNameEn"
          placeholder="예 : Salvador Dalí"
          :onChange="
            (e) => handleChangeProductData('artistNameEn', e.target.value)
          "
        ></Input>
      </div>
    </div>
    <div class="grid grid-cols-4 gap-10 mt-6">
      <div class="col-span-2">
        <FormLabel
          name="재질(Medium)"
          description="한글이나 영어로 입력해주세요. 자동추천되는 내용이 없으면 직접 입력해주세요."
        ></FormLabel>
        <Autocomplete
          className="w-full"
          placeholder="장지위에 분채, 석채 / natural color on Korean paper, janggi"
          :initialSearch="productData.medium"
          :onChange="(e) => handleChangeProductData('medium', e.target.value)"
          :suggestions="mediumOptions"
          :onSelect="(item) => handleChangeProductData('medium', item.value)"
        ></Autocomplete>
      </div>
      <div class="">
        <FormLabel
          name="작품사이즈"
          description="가로/세로/깊이 cm"
          popoverDescription="프레임이나 포장을 제외한 작품의 크기를 cm 단위로 입력해주세요.(소숫점 사용가능)"
        ></FormLabel>
        <Input
          className="w-full"
          placeholder="55/100/4"
          textAlign="right"
          unit="cm"
          :value="productData.size"
          :onChange="(e) => handleChangeProductData('size', e.target.value)"
        ></Input>
      </div>
    </div>
    <div class="grid grid-cols-4 gap-10 mt-6">
      <div>
        <FormLabel
          name="제작시기"
          description="르네상스시대, 1900년대, 현대 등"
        ></FormLabel>
        <Input
          className="w-full"
          placeholder="예 : 20세기"
          :value="productData.period"
          :onChange="
            (e) => handleChangeProductData('period', e.target.value)
          "
        ></Input>
      </div>
      <div>
        <FormLabel
          name="제작년도"
          description="2022년 등 구체적인 년도 입력"
        ></FormLabel>
        <Input
          className="w-full"
          placeholder="예 : 1931년"
          :value="productData.makeYear"
          :onChange="
            (e) => handleChangeProductData('makeYear', e.target.value)
          "
        ></Input>
      </div>
      <div>
        <FormLabel
          name="에디션"
          description="1/100"
        ></FormLabel>
        <Input
          className="w-full"
          placeholder="1/100"
          :value="productData.edition"
          :onChange="
            (e) => handleChangeProductData('edition', e.target.value)
          "
        ></Input>
      </div>
      <div>
        <FormLabel
          name="국가"
          description="작품(제품)의 제작 국가"
        ></FormLabel>
        <Autocomplete
          className="w-full"
          placeholder="예 : 대한민국"
          :initialSearch="productData.country"
          :onChange="
            (e) => handleChangeProductData('country', e.target.value)
          "
          :suggestions="countryOptions"
          :onSelect="(item) => handleChangeProductData('country', item.value)"
        ></Autocomplete>
      </div>
    </div>
    <div class="grid grid-cols-4 gap-10 mt-6">
      <div class="col-span-2">
        <FormLabel
          name="서명정보"
          description="서명에 대한 구체적인 설명을 입력해주세요."
        ></FormLabel>
        <Input
          className="w-full"
          placeholder="예 : 작품 우하단에 자필 서명"
          :value="productData.signature"
          :onChange="
            (e) => handleChangeProductData('signature', e.target.value)
          "
        ></Input>
      </div>
      <div>
        <FormLabel name="보증서 유무"></FormLabel>
        <Select
          className="border-black w-full"
          :value="productData.certificate"
          :options="[
              {
                label: '보증서 있음',
                value: true,
              },
              {
                label: '보증서 없음',
                value: false,
              },
            ]"
          :onChange="
              (e) => {
                handleChangeProductData(
                  'certificate',
                  e.target.value === 'true'
                );
              }
            "
        ></Select>
      </div>
      <div>
        <FormLabel
          name="상태(컨디션)"
          description="작품(제품)의 상태를 자세히 입력해주세요."
        ></FormLabel>
        <Input
          className="w-full"
          placeholder="예 : 새것과 같음"
          :value="productData.status"
          :onChange="
            (e) => handleChangeProductData('status', e.target.value)
          "
        ></Input>
      </div>
    </div>
    <div class="grid grid-cols-4 gap-10 mt-6">
      <div class="col-span-3">
        <!-- NOTE: https://www.npmjs.com/package/@sipec/vue3-tags-input -->
        <FormLabel
          name="태그(Tag)"
          description="상품의 특성을 설명할 수 있는 태그를 입력하시면 검색시 노출이 용이합니다."
        ></FormLabel>
        <Input
          className="w-full"
          placeholder="콤마로 구분해주세요. 예 : 이건희컬렉션, 한정판매"
          :value="productData.tags"
          :onChange="(e) => handleChangeProductData('tags', e.target.value)"
        ></Input>
        <!-- TODO: 선택된 태그 렌더링 -->
      </div>
      <div>
        <FormLabel
          name="이벤트 코드"
          description="영어소문자, 숫자 조합으로 20자 이내"
        ></FormLabel>
        <Input
          className="w-full"
          placeholder="예 : blacklot20220418"
          :value="productData.eventCode"
          :onChange="
            (e) => handleChangeProductData('eventCode', e.target.value)
          "
        ></Input>
      </div>
    </div>
    <div class="grid grid-cols-2 mt-6 gap-10">
      <div class="">
        <FormLabel
          name="소장이력"
        ></FormLabel>
        <div>
          <textarea
            rows="6"
            class="w-full p-2 border border-black focus:outline-none"
            :value="productData.ownHistory"
            @change="
              (e) => handleChangeProductData('ownHistory', e.target.value)
            "
          ></textarea>
        </div>
      </div>
      <div class="">
        <FormLabel
          name="수록처"
        ></FormLabel>
        <div>
          <textarea
            rows="6"
            class="w-full p-2 border border-black focus:outline-none"
            :value="productData.recordedPlace"
            @change="
              (e) => handleChangeProductData('recordedPlace', e.target.value)
            "
          ></textarea>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-2 mt-6 gap-10">
      <div class="">
        <FormLabel
          name="전시이력"
        ></FormLabel>
        <div>
          <textarea
            rows="6"
            class="w-full p-2 border border-black focus:outline-none"
            :value="productData.exhibitionHistory"
            @change="
              (e) => handleChangeProductData('exhibitionHistory', e.target.value)
            "
          ></textarea>
        </div>
      </div>
      <div class="">
        <FormLabel
          name="작가정보"
        ></FormLabel>
        <div>
          <textarea
            rows="6"
            class="w-full p-2 border border-black focus:outline-none"
            :value="productData.artistInfo"
            @change="
              (e) => handleChangeProductData('artistInfo', e.target.value)
            "
          ></textarea>
        </div>
      </div>
    </div>
    <div class="grid mt-6">
      <div class="">
        <FormLabel
          name="상세설명"
        ></FormLabel>
        <div>
          <textarea
            rows="6"
            class="w-full p-2 border border-black focus:outline-none"
            :value="productData.description"
            @change="
              (e) => handleChangeProductData('description', e.target.value)
            "
          ></textarea>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center mt-14">
    <button
        class="
        w-60
        h-16
        border border-black
        shadow
        flex
        items-center
        justify-center
      "
        @click="handleSubmitStep1"
    >
      <IconBase>
        <CheckCircleIcon></CheckCircleIcon>
      </IconBase>
      <span class="ml-2">상품정보 등록완료</span>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import _ from 'lodash';
import FormLabel from '@/components/FormLabel.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import DropboxIcon from '@/components/icons/DropboxIcon.vue';
import Input from '@/components/Input.vue';
import Select from '@/components/Select.vue';
import Popover from '@/components/Popover.vue';
import ImportantIcon from '@/components/icons/ImportantIcon.vue';
import CategoryTitle from './CategoryTitle.vue';
import IconBase from '@/components/icons/IconBase.vue';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import partnerAPI from '@/service/partnerAPI';
import Autocomplete from '@/components/Autocomplete.vue';
import router from '@/router';
import axios from 'axios';
import useStatusOptions from '@/mixins/useStatusOptions';
import useCategoryOptions from '@/mixins/useCategoryOptions';
import getServerErrorMessage from '@/utils/getServerErrorMessage';
import convertNumWithComma, { removeComma } from '@/utils/convertNumWithComma';

export default defineComponent({
  name: 'Step1',
  components: {
    CategoryTitle,
    FormLabel,
    Input,
    Select,
    ImportantIcon,
    Popover,
    CheckCircleIcon,
    IconBase,
    DropboxIcon,
    Autocomplete,
  },
  mixins: [scrollToTopOnMountMixin],
  props: {
    productIdQuery: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const artistOptions = ref([]);
    const countryOptions = ref([]);
    const mediumOptions = ref([]);
    const { statusOptions } = useStatusOptions();
    const { categoryOptions } = useCategoryOptions();
    const periodOptions = ref([]);
    const imageFilesInputEl = ref<HTMLInputElement | null>(null);

    const isCreateProduct = computed(() => !props.productIdQuery); // 새로운 상품정보 등록인지. 상품 아이디가 존재 유무로 확인한다

    const savedProductData = ref<any>({} as any); // 서버에서 가져온 상품정보
    const productData = ref<any>({
      productImageList: [],
      signatureExist: true,
      status: '',
    } as any); // 폼으로 제어할 상품정보

    const selectedCategoryDepth1 = computed(() =>
        categoryOptions.value.find(
            (v) => v.categoryId === productData.value.categoryDepth1Id
        )
    );

    const handleChangeProductData = (field: string, value: any) => {
      // productData.value[field] = value;
      productData.value = {
        ...productData.value,
        [field]: value,
      };
      console.group('handleChangeProductData');
      console.log('field, value', field, value);
      console.groupEnd();
    };

    const handleRemoveProductImage = (index, url) => {
      const nextList = [...(productData.value.productImageList || [])];
      nextList.splice(index, 1);
      productData.value.productImageList = nextList;
    };

    const initProductData = async () => {
      if (!isCreateProduct.value) {
        try {
          const res = await partnerAPI.adminProduct.adminProductSingle({
            productId: Number(props.productIdQuery),
          });

          const data = (res.data as any).data;
          savedProductData.value = data;
          productData.value = data;
        } catch (error) {
          console.error(error);
        }
      }
    };

    onMounted(initProductData);

    onMounted(() => {
      // get artist options
      partnerAPI.adminProduct
          .adminOptionList({
            type: 'artist',
          })
          .then(({ data }) => {
            artistOptions.value = (data as any).data.map((v: any) => ({
              label: `${v.kor} ${v.eng}`,
              value: `${v.kor} ${v.eng}`,
            }));
          });

      // get country options
      partnerAPI.adminProduct
          .adminOptionList({
            type: 'country',
          })
          .then(({ data }) => {
            countryOptions.value = (data as any).data.map((v: any) => ({
              label: `${v.kor} ${v.eng}`,
              value: `${v.kor} ${v.eng}`,
            }));
          });

      // get medium options
      partnerAPI.adminProduct
          .adminOptionList({
            type: 'medium',
          })
          .then(({ data }) => {
            mediumOptions.value = (data as any).data.map((v: any) => ({
              label: `${v.kor} ${v.eng}`,
              value: `${v.kor} ${v.eng}`,
            }));
          });

      // get period options
      partnerAPI.adminProduct
          .adminOptionList({
            type: 'period',
          })
          .then(({ data }) => {
            periodOptions.value = (data as any).data.map((v: any) => ({
              label: v.kor,
              value: v.kor,
            }));
          });
    });

    watch([categoryOptions, savedProductData], ([categories, product]) => {
      if (categories.length > 0) {
        console.log(categories);

        console.log('product.categoryId', product.categoryId);

        // 저장된 상품 수정일 때
        if (product.categoryDepth1Id) {
          handleChangeProductData('categoryDepth1Id', product.categoryDepth1Id);

          // depth2 가 없는 카테고리도 있으므로 확인 후 업데이트
          if (product.categoryDepth2Id) {
            handleChangeProductData(
                'categoryDepth2Id',
                product.categoryDepth2Id
            );
          }
        } else {
          // 최초 등록일 때
          handleClickCategory(0);
        }
      }
    });

    const handleClickCategory = (index: number) => {
      const hasChildCategories =
          categoryOptions.value[index].children.length > 0;

      handleChangeProductData(
          'categoryDepth1Id',
          categoryOptions.value[index].categoryId
      );

      if (hasChildCategories) {
        // children 중에 첫번째 항목을 기본값으로 선택
        handleChangeProductData(
            'categoryDepth2Id',
            categoryOptions.value[index].children[0].categoryId
        );
      } else {
        handleChangeProductData('categoryDepth2Id', null);
      }
    };

    const handleClickSubCategory = (subCategory: any) => {
      handleChangeProductData('categoryDepth2Id', subCategory.categoryId);
    };

    const handleDragoverImageInput = async (e) => {
      e.preventDefault();
      if (!e.currentTarget.classList.contains('is--drag')) {
        e.currentTarget.classList.add('is--drag');
      }
    };

    const handleDragleaveImageInput = async (e) => {
      e.currentTarget.classList.remove('is--drag');
    };

    const handleClickImageInput = async (e) => {
      if (imageFilesInputEl.value) {
        imageFilesInputEl.value.click();
      }
    };

    const handleChangeImageInput = async (e) => {
      if (e.target.files) {
        console.log('e.target.files', e.target.files);
        uploadImageFiles(e.target.files);
      }
    };

    const handleDropImageInput = async (event) => {
      event.preventDefault();
      event.currentTarget.classList.remove('is--drag');

      //TODO: 사파리에서 파일이 2번 올라간다. input change가 같이 작동함. 크롬은 아님.
      if (imageFilesInputEl.value && imageFilesInputEl.value.files !== null) {
        imageFilesInputEl.value.files = event.dataTransfer.files;
        uploadImageFiles(event.dataTransfer.files);
      }
    };

    const uploadImageFiles = async (files: FileList) => {
      const MAX_IMAGES = 10;
      const uploadableLength =
          MAX_IMAGES - productData.value.productImageList.length;

      if (files.length > uploadableLength) {
        alert(`이미지 파일은 ${MAX_IMAGES}개까지 등록 가능합니다.`);
      }

      try {
        let uploadedImageUrls: { [key: string]: any }[] = [];

        for (let i = 0; i < Math.min(files.length, uploadableLength); i++) {
          const formData = new FormData();
          formData.append('file', files[i]);

          const { data: uploadedImageUrl } = await axios.post(
              process.env.VUE_APP_API_HOST + '/admin-api/upload',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
          );

          uploadedImageUrls = [
            ...uploadedImageUrls,
            {
              productId: !props.productIdQuery // 상품아이디, create일때는 null
                  ? null
                  : productData.value.productId,
              url: uploadedImageUrl,
            },
          ];
        }

        const nextImageUrls =
            productData.value.productImageList.concat(uploadedImageUrls);

        handleChangeProductData('productImageList', nextImageUrls);
      } catch (error) {
        console.error(error);
      }
    };

    const handleSubmitStep1 = _.throttle(async () => {
      // TODO: form validation
      const submitData = { param: { ...productData.value } };
      console.log('submitData', submitData);
      try {
        // NOTE: 상품 업데이트
        await partnerAPI.adminProduct.adminProductUpdate(submitData as any);
        alert('상품 정보가 수정되었습니다');
        // step3로 이동.
        router.push({
          path: '/app/product/register',
          query: {
            step: 3,
            productId: productData.value.productId,
          },
        });
      } catch (e) {
        console.error(e);
        alert(getServerErrorMessage(e));
      }
    }, 2000);

    return {
      productData,
      handleChangeProductData,
      categoryOptions,
      artistOptions,
      countryOptions,
      mediumOptions,
      statusOptions,
      periodOptions,
      selectedCategoryDepth1,
      handleClickCategory,
      handleClickSubCategory,
      handleSubmitStep1,
      handleDragoverImageInput,
      handleDragleaveImageInput,
      handleDropImageInput,
      handleClickImageInput,
      handleChangeImageInput,
      imageFilesInputEl,
      handleRemoveProductImage,
      convertNumWithComma,
      removeComma,
    };
  },
});
</script>

<style lang="scss" scoped>
.ProductImageItem {
  & > button {
    display: none;
  }
  &:hover > button {
    display: block;
  }
}

.drag-container {
  &.is--drag {
    width: 100%;
    height: 200px;
    background: url("../../../assets/hover-img.png") no-repeat 50% 50%/ contain;
    .drag-text {
      display: none;
    }
  }
}
</style>
