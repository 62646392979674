<template>
  <CategoryTitle
      title="판매 설정하기"
      description="판매정보를 설정하셔야 경매가 시작됩니다. (* 은 필수입력 항목입니다.)"
      className="mt-10"
  ></CategoryTitle>

  <div class="border mt-6">
    <div class="flex items-center h-18 px-6 border-b border-gray">
      상품판매에 대한 제반사항 동의
    </div>

    <div
        class="text-gray-400 p-12 overflow-y-auto"
        style="height: 408px"
        v-html="
        `
이용약관<br/>
<br/>
제1조 (목적) <br/>
1.	이 약관은 (주)서울옥션(이하 ‘회사’라 합니다)이 운영하는 블랙랏 웹 및 모바일에서 제공하는 전자상거래 관련 서비스(이하 ‘서비스’라 합니다) 이용자 및 회사 사이의 권리·의무 및 책임사항과 서비스 이용 절차에 관한 사항을 규정함을 목적으로 합니다.<br/>
2.	회사는 통신판매중개자로서 거래당사자가 아니며, 판매자가 등록한 상품정보 및 거래에 대하여 본 약관에 명시돼 있는 내용 이외의 책임을 지지 않습니다.<br/>
<br/>
제2조 (정의)<br/>
1.	회원: 본 약관에 동의하고 회사와 서비스 이용계약을 체결한 자로서, 판매자(셀러 Seller 혹은 파트너)와 구매자(비더 Bidder 혹은 응찰자)가 이에 해당합니다.<br/>
2.	판매자(셀러 Seller 혹은 파트너): 물품을 판매하기 위해 회사의 판매자 등록 절차를 완료한 회원을 말합니다. 물품의 통신 매매를 업(業)으로 하는 판매자(개인인지 법인인지 여부를 불문)인 경우와 그렇지 아니한 판매자인 경우 판매자 등록 절차와 이용하는 서비스에 차이가 있을 수 있습니다.<br/>
3.	구매자(비더 Bidder 혹은 응찰자): 판매자가 판매하는 물품을 구매하고자 하는 회원을 말합니다.<br/>
4.	위탁자: 본인 소유의 상품을 회사의 기획 경매 혹은 판매 방법에 의하여 판매하고자 하는 사람을 말합니다.<br/>
5.	비드나우(Bid Now 혹은 경매 응찰 혹은 응찰): 경매 물품을 구매하기 위하여 원하는 매수가격을 제출하는 행위를 말합니다.<br/>
`
      "
    ></div>

    <div
        class="
        flex
        items-center
        justify-center
        h-18
        px-6
        border-t border-gray
        text-sm
      "
    >
      <Checkbox
          :checked="isAgreedTerms"
          :onChange="(e) => (isAgreedTerms = e.target.checked)"
          name="isAgreedTerms"
          label="위 사항에 대해 확인하고 동의합니다."
      ></Checkbox>
    </div>
  </div>

  <div class="mt-8">
    <div class="border-t border-dashed border-gray-500 h-20 flex items-center">
      <div class="Step2__formLabelWrapper">
        <FormLabel
            name="판매 옵션"
            :isRequired="true"
        ></FormLabel>
      </div>
      <div class="w-80 mr-6">
        <RadioInput
            id="buynowOnlyFalse"
            name="enableBid"
            label="Bid Now"
            description="경매방식으로 판매합니다."
            value="true"
            :selected="productData.enableBid ? 'true' : 'false'"
            :onChange="
            (e) => {
              handleChangeProductData('enableBid', true);
              handleChangeProductData('buynowOnly', false);
              handleChangeProductData('buynowMakeOffer', false);
            }
          "
        ></RadioInput>
      </div>
      <div class="mr-6">
        <RadioInput
            id="buynowOnlyTrue"
            name="enableBid"
            label="Buy Now Only"
            description="즉시구매 방식으로 판매합니다."
            value="false"
            :selected="!productData.enableBid ? 'false' : 'true'"
            :onChange="
            (e) => {
              handleChangeProductData('enableBid', false);
              handleChangeProductData('buynowOnly', true);
            }
          "
        ></RadioInput>
      </div>
    </div>

    <!--  Bid now 일 때 가격 설정-->
    <div v-if="productData.enableBid" class="border-t h-20 flex items-center">
      <div class="Step2__formLabelWrapper">
        <FormLabel
            name="시작가"
            description="0을 입력하면 0원부터 경매가 시작됩니다."
            :isRequired="true"
        ></FormLabel>
      </div>
      <Input
          className="w-40"
          placeholder="0"
          textAlign="right"
          unit="원"
          :value="convertNumWithComma(productData.startPrice, '', '')"
          :onKeyup="
          (e) =>
            handleChangeProductData(
              'startPrice',
              parseInt(removeComma(e.target.value))
            )
        "
      ></Input>

      <Checkbox
          className="ml-4"
          :checked="productData.enableBuyNow"
          :onChange="
          (e) => handleChangeProductData('enableBuyNow', e.target.checked)
        "
          name="enableBuyNow"
          label="Buy Now 설정"
          description="설정금액으로 즉시 구매가 가능합니다."
      ></Checkbox>

      <Input
          className="w-40 ml-7"
          placeholder="0"
          textAlign="right"
          unit="원"
          :disabled="!productData.enableBuyNow"
          :value="convertNumWithComma(productData.buynowPrice, '', '')"
          :onKeyup="(e) => handleChangeProductData('buynowPrice', parseInt(removeComma(e.target.value)))"
      ></Input>
    </div>

    <!-- Buynow only 일 때 가격 설정-->
    <div v-if="productData.buynowOnly" class="border-t h-20 flex items-center">
      <div class="Step2__formLabelWrapper">
        <FormLabel
            name="Buy Now 희망가격"
            description="설정 금액으로 판매합니다."
            :isRequired="true"
        ></FormLabel>
      </div>
      <Input
          className="w-80"
          placeholder="0"
          textAlign="right"
          unit="원"
          :value="convertNumWithComma(productData.buynowPrice, '', '')"
          :onKeyup="
          (e) =>
            handleChangeProductData(
              'buynowPrice',
              parseInt(removeComma(e.target.value))
            )
        "
      ></Input>

      <Checkbox
          className="ml-6"
          :checked="productData.buynowMakeOffer"
          :onChange="
          (e) => handleChangeProductData('buynowMakeOffer', e.target.checked)
        "
          name="buynowMakeOffer"
          label="Make Offer 허용"
          description="구매 희망자의 금액 제안을 허용합니다."
      ></Checkbox>
    </div>

    <div class="border-t h-20 flex items-center">
      <div class="Step2__formLabelWrapper">
        <FormLabel name="구매 제한" :isRequired="true"></FormLabel>
      </div>

      <div class="w-80 mr-6">
        <RadioInput
            id="buy_limit_false"
            name="buy_limit"
            label="제한 없음"
            description="구매제한을 두지 않습니다."
            value="false"
            :selected="productData.buyLimit ? 'true' : 'false'"
            :onChange="
            (e) =>
              handleChangeProductData('buyLimit', e.target.value === 'true')
          "
        ></RadioInput>
      </div>
      <div class="mr-6">
        <RadioInput
            id="buy_limit_true"
            name="buy_limit"
            label="19세 이상만 구매가능"
            description="와인과 위스키 및 일부 품목은 19세 이상만 입찰이 가능합니다."
            value="true"
            :selected="productData.buyLimit ? 'true' : 'false'"
            :onChange="
            (e) =>
              handleChangeProductData('buyLimit', e.target.value === 'true')
          "
        ></RadioInput>
      </div>
    </div>

    <div class="border-t h-20 flex items-center">
      <div class="Step2__formLabelWrapper">
        <FormLabel name="면세/과세여부" :isRequired="true"></FormLabel>
      </div>

      <div class="w-80 mr-6">
        <RadioInput
            id="tax_free_true"
            name="tax_free"
            label="면세상품"
            description="미술품 등 면세상품."
            value="true"
            :selected="productData.taxFree ? 'true' : 'false'"
            :onChange="
            (e) => handleChangeProductData('taxFree', e.target.value === 'true')
          "
        ></RadioInput>
      </div>
      <div class="mr-6">
        <RadioInput
            id="tax_free_false"
            name="tax_free"
            label="과세상품"
            description="부가가치세가 붙는 일반 상품"
            value="false"
            :selected="productData.taxFree ? 'true' : 'false'"
            :onChange="
            (e) => handleChangeProductData('taxFree', e.target.value === 'true')
          "
        ></RadioInput>
      </div>
    </div>

    <div v-if="productData.enableBid" class="border-t h-20 flex items-center">
      <div class="Step2__formLabelWrapper">
        <FormLabel
            name="판매 기간"
            description="최대 14일까지 설정 가능합니다."
            :isRequired="true"
        ></FormLabel>
      </div>

      <div class="flex items-center border border-black overflow-hidden">
        <Input
            :min="getNowDateString()"
            type="datetime-local"
            className="border-none"
            style="width: 240px"
            :value="
            productData.startDate
              ? getDateTimeLocalValue(productData.startDate)
              : ''
          "
            :onChange="
            (e) => {
              handleChangeDate('startDate', e.target.value);
              validateDate();
            }
          "
        />
        <span>~</span>
        <Input
            :min="getNowDateString()"
            type="datetime-local"
            className="border-none dateTime"
            style="width: 240px"
            :value="
            productData.endDate
              ? getDateTimeLocalValue(productData.endDate)
              : ''
          "
            :onChange="
            (e) => {
              handleChangeDate('endDate', e.target.value);
              validateDate();
            }
          "
        />
      </div>
    </div>

    <div v-if="productData.buynowOnly" class="border-t h-20 flex items-center">
      <div class="Step2__formLabelWrapper">
        <FormLabel name="판매 시작" :isRequired="true"></FormLabel>
      </div>

      <div class="flex items-center border border-black overflow-hidden">
        <Input
            :min="getNowDateString()"
            type="datetime-local"
            className="border-none"
            style="width: 240px"
            :value="
            productData.startDate
              ? getDateTimeLocalValue(productData.startDate)
              : ''
          "
            :onChange="(e) => handleChangeDate('startDate', e.target.value)"
        />
      </div>
    </div>

    <div class="border-t h-20 flex items-center">
      <div class="Step2__formLabelWrapper">
        <FormLabel name="배송 방법 설정" :isRequired="true"></FormLabel>
      </div>

      <!-- TODO: 옵션 설정-->
      <Select
          className="border-black w-80"
          :value="productData.shippingTypeId?.toString() || ''"
          :onChange="
          // shippingTypeId는 Number 타입
          (e) =>
            handleChangeProductData('shippingTypeId', parseInt(e.target.value))
        "
          :options="shippingTypeOptions"
      ></Select>
    </div>

    <div class="border-t h-20 flex items-center">
      <div class="Step2__formLabelWrapper">
        <FormLabel
            name="배송비"
            description="미설정시 판매자 부담"
            :isRequired="true"
        ></FormLabel>
      </div>
      <Input
          :disabled="!productData.feePrepaidShipping"
          className="w-80"
          placeholder="0"
          textAlign="right"
          unit="원"
          :value="convertNumWithComma(productData.shippingFee, '', '')"
          :onKeyup="
          (e) =>
            handleChangeProductData(
              'shippingFee',
              parseInt(removeComma(e.target.value))
            )
        "
      ></Input>
      <Checkbox
          className="ml-4"
          :checked="productData.feePrepaidShipping"
          :onChange="
          (e) => {
            handleChangeProductData('feePrepaidShipping', e.target.checked);
            handleChangeProductData('shippingFee', 0);
          }
        "
          name="feePrepaidShipping"
          label="배송비 구매자 부담"
          description="낙찰가격에 배송비를 더한 금액이 구매자에게 청구됩니다."
      ></Checkbox>
    </div>
  </div>

  <div class="border-t h-20 flex items-center">
    <div class="Step2__formLabelWrapper">
      <FormLabel name="환불 가능 여부" :isRequired="true"></FormLabel>
    </div>

    <div class="w-80 mr-6">
      <RadioInput
          id="enable_refund_true"
          name="refund_true"
          label="환불 가능"
          value="true"
          :selected="productData.enableRefund ? 'true' : 'false'"
          :onChange="
            (e) => handleChangeProductData('enableRefund', e.target.value === 'true')
          "
      ></RadioInput>
    </div>
    <div class="mr-6">
      <RadioInput
          id="enable_refund_false"
          name="refund_false"
          label="환불 불가능"
          value="false"
          :selected="productData.enableRefund ? 'true' : 'false'"
          :onChange="
            (e) => handleChangeProductData('enableRefund', e.target.value === 'true')
          "
      ></RadioInput>
    </div>
  </div>

  <div class="border-t h-20 flex items-center">
    <div class="Step2__formLabelWrapper">
      <FormLabel name="Register Token" :isRequired="true"></FormLabel>
    </div>

    <div class="w-80 mr-6">
      <RadioInput
          id="enable_register_token_true"
          name="register_token_true"
          label="Private"
          value="true"
          :selected="productData.enableRegisterToken ? 'true' : 'false'"
          :onChange="
            (e) => handleChangeProductData('enableRegisterToken', e.target.value === 'true')
          "
      ></RadioInput>
    </div>
    <div class="mr-6">
      <RadioInput
          id="enable_register_token_false"
          name="register_token_false"
          label="Public"
          value="false"
          :selected="productData.enableRegisterToken ? 'true' : 'false'"
          :onChange="
            (e) => handleChangeProductData('enableRegisterToken', e.target.value === 'true')
          "
      ></RadioInput>
    </div>
  </div>
  <div class="border-t h-20 flex items-center">
    <div class="Step2__formLabelWrapper">
      <FormLabel
          name="구매수수료율 설정"
          description="ex) 19.8% 일 경우 0.198 입력"
          :isRequired="true"
      ></FormLabel>
    </div>
    <Input
        className="w-80"
        placeholder="0"
        textAlign="right"
        unit=""
        :value="productData.supplyFeeRate"
        :onKeyup="
            (e) =>
              handleChangeProductData(
                'supplyFeeRate',
                e.target.value
              )
          "
    ></Input>
  </div>

  <div class="flex justify-center mt-14">
    <button
        v-if="!isBidDataRegistered"
        class="
        w-60
        h-16
        border border-black
        shadow
        flex
        items-center
        justify-center
      "
        @click="handleClickRegisterWithoutInput"
    >
      <CircleXIcon></CircleXIcon>
      <span class="ml-2">판매설정 없이 상품 등록</span>
    </button>
    <button
        class="
        w-60
        h-16
        border border-black
        shadow
        flex
        items-center
        justify-center
        ml-5
      "
        @click="handleSubmitCompleteStep2"
    >
      <IconBase>
        <CheckCircleIcon></CheckCircleIcon>
      </IconBase>
      <span class="ml-2">판매설정 완료</span>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import _ from 'lodash';
import CategoryTitle from './CategoryTitle.vue';
import Input from '@/components/Input.vue';
import RadioInput from '@/components/RadioInput.vue';
import Checkbox from '@/components/Checkbox.vue';
import Select from '@/components/Select.vue';
import FormLabel from '@/components/FormLabel.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import CircleXIcon from '@/components/icons/CircleXIcon.vue';
import IconBase from '@/components/icons/IconBase.vue';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format';
import datePattern from '@/constants/datePattern';
import router from '@/router';
import getServerErrorMessage from '@/utils/getServerErrorMessage';
import convertNumWithComma, { removeComma } from '@/utils/convertNumWithComma';
import useShippingTypeOptions from '@/mixins/useShippingTypeOptions';

export default defineComponent({
  name: 'Step2',
  components: {
    CategoryTitle,
    Input,
    RadioInput,
    Checkbox,
    Select,
    FormLabel,
    CheckCircleIcon,
    CircleXIcon,
    IconBase,
  },
  mixins: [scrollToTopOnMountMixin],
  props: {
    productIdQuery: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isAgreedTerms = ref(false);

    const DEFAULT_SELL_INFO = {
      enableBid: true,
      buynowOnly: false,
      buyLimit: false,
      buynowMakeOffer: false,
      buynowPrice: 0,
      enableBuyNow: false,
      startDate: null,
      endDate: null,
      feePrepaidShipping: false,
      shippingFee: 0,
      shippingTypeId: 1,
      startPrice: 0,
      taxFree: false,
      enableRefund: true,
      enableRegisterToken: false,
      supplyFeeRate: 0
    }; // 폼 기본값
    const productData = ref<any>({
      ...DEFAULT_SELL_INFO,
    }); // 폼으로 제어할 상품정보
    const savedProductData = ref<any>({}); // 서버에서 가져온 상품정보

    const isBidDataRegistered = ref(false); // 판매정보 등록 여부는 판개기간이 등록되었는지로 확인한다.

    const getProductData = async () => {
      // 판매정보가 등록되어 있다면 데이터를 가져온다
      try {
        const res = await partnerAPI.adminProduct.adminProductSingle({
          productId: Number(props.productIdQuery),
        });

        const data = (res.data as any).data;

        savedProductData.value = data;
      } catch (error) {
        console.error(error);
      }
    };

    onMounted(() => {
      // step2에는 임시저장한 상품아이디가 필요하다.
      if (!props.productIdQuery) {
        alert('There is no product id');
        router.push({
          path: '/app/product/register',
          query: {
            step: 1,
          },
        });
      } else {
        getProductData();
      }
    });

    watch(savedProductData, (product) => {
      isBidDataRegistered.value = product.enableBid || product.buynowOnly;

      if (isBidDataRegistered.value) {
        // 판매정보가 등록되어 있으면
        productData.value = savedProductData.value;
      } else {
        // 등록되어 있지 않은 경우
        productData.value = {
          productId: parseInt(props.productIdQuery),
          ...DEFAULT_SELL_INFO,
          taxFree: savedProductData.value.categoryDepth1Id === 1, // art 카테고리 기본 면세 설정
        };
      }

      console.group('savedProductData');
      console.log('value', productData.value);
      console.log('isBidDataRegistered.value', isBidDataRegistered.value);
      console.groupEnd();
    });

    const handleChangeProductData = (field: string, value: any) => {
      productData.value[field] = value;
      console.group('handleChangeProductData');
      console.log('field.value', field, value);
      console.groupEnd();
    };

    const handleChangeDate = (field, value) => {
      try {
        const isoDate = new Date(value).toISOString();
        handleChangeProductData(field, isoDate);
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 판매설정 없이 상품 등록. step1에서 임시저장되었으므로 step3으로 직행
     */
    const handleClickRegisterWithoutInput = () => {
      router.push({
        path: '/app/product/register',
        query: {
          step: 3,
          productId: props.productIdQuery,
        },
      });
    };

    /**
     * 판매설정 완료
     * TODO: 유효성 검사 후 상품저장
     */
    const handleSubmitCompleteStep2 = _.throttle(async () => {
      if (!isAgreedTerms.value) {
        alert('상품판매에 대한 제반사항에 동의해야 합니다.');
        return;
      }
      const { enableBid, enableBuyNow, startPrice, buynowPrice } = productData.value;
      if (enableBid && (startPrice !== 0 && !startPrice)) {
        alert('시작가를 입력해 주세요.');
        productData.value.buynowPrice = null;
        return;
      }
      if (enableBid && enableBuyNow && buynowPrice <= startPrice) {
        alert('바이나우 금액은 시작가보다 높게 설정해야합니다.');
        productData.value.buynowPrice = null;
        return;
      }

      if (!isBidDataRegistered.value) {
        try {
          const submitData = {
            param: {
              ...productData.value,
            },
          };

          const { data } = await partnerAPI.adminProduct.adminProductAddStep2(
              submitData
          );

          alert((data as any).message);
          // step3로 이동.
          router.push({
            path: '/app/product/register',
            query: {
              step: 3,
              productId: props.productIdQuery,
            },
          });
        } catch (e) {
          console.error(e);
          alert(getServerErrorMessage(e));
        }
      } else {
        try {
          const submitData = {
            param: {
              ...productData.value,
            },
          };

          // NOTE: 상품 업데이트
          await partnerAPI.adminProduct.adminProductUpdate(submitData as any);

          alert('판매 정보가 수정되었습니다');
          // step3로 이동.
          router.push({
            path: '/app/product/register',
            query: {
              step: 3,
              productId: props.productIdQuery,
            },
          });
        } catch (e) {
          console.error(e);
          alert(getServerErrorMessage(e));
        }
      }
    }, 2000);

    const { shippingTypeOptions } = useShippingTypeOptions(false);

    const getDateTimeLocalValue = (date) => {
      return `${format(new Date(date), datePattern.INPUT_DATE)}T${format(
          new Date(date),
          datePattern.INPUT_TIME_24
      )}`;
    };

    const getNowDateString = () => {
      const today = new Date();
      return `${format(today, datePattern.INPUT_DATE)}T${format(today, datePattern.INPUT_TIME_24)}`
    };

    const validateDate = () => {
      const { startDate, endDate, enableBid } = productData.value;
      // 14일 이내 범위로 제한한다.
      if (enableBid && startDate && endDate) {
        const startTime = new Date(startDate).getTime() / 1000;
        const endTime = new Date(endDate).getTime() / 1000;

        if (startTime > endTime) {
          alert('판매 시작일이 먼저여야 합니다.');
          productData.value.endDate = null;
          return;
        }
        const TWO_WEEK_TIME = 60 * 60 * 24 * 14;
        if (endTime - startTime > TWO_WEEK_TIME) {
          alert('판매기간 범위는 14일 이내로 설정해야 합니다.');
          productData.value.endDate = new Date(
              (startTime + TWO_WEEK_TIME) * 1000
          ).toISOString();
        }
      }
    };

    return {
      productData,
      savedProductData,
      handleChangeProductData,
      handleChangeDate,
      isAgreedTerms,
      handleClickRegisterWithoutInput,
      handleSubmitCompleteStep2,
      format,
      datePattern,
      shippingTypeOptions,
      isBidDataRegistered,
      getDateTimeLocalValue,
      convertNumWithComma,
      removeComma,
      validateDate,
      getNowDateString,
    };
  },
});
</script>

<style lang="scss" scoped>
.Step2__formLabelWrapper {
  width: 330px;
  flex-shrink: 0;
}
</style>
