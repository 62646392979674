<template>
  <div class="flex flex-1 items-center">
    <div
        :class="[
        'flex items-center justify-center w-15 h-15 mr-6 rounded-full text-base border',
        isActive ? 'text-white bg-black border-black' : 'border',
      ]"
    >
      {{ step }}
    </div>
    <div class="flex flex-col items-start flex-1">
      <div class="flex justify-start items-center w-full">
        <span class="text-xl mr-11">{{ stepList[step - 1].name }}</span>
        <span class="block flex-1 h-0.25 bg-gray mr-20">&nbsp;</span>
      </div>
      <div class="mt-2 text-xs">{{ stepList[step - 1].description }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CurrentStep',
  props: {
    step: Number, // 1부터 시작
    isActive: Boolean,
  },
  data() {
    return {
      stepList: [
        {
          name: '상품정보 입력',
          description: '상품정보와 이미지를 등록해주세요',
        },
        {
          name: '판매설정',
          description: '판매옵션을 설정해주세요.',
        },
        {
          name: '설정확인',
          description: '등록된 정보를 확인해주세요.',
        },
      ],
    };
  },
});
</script>

<style lang="scss" scoped></style>
